@import "@interactio/component-library/styles";

html, body, #root {
  height: 100vh;
  min-height: 100vh;
}

* {
  position: relative;
}

iframe {
  max-width: 100%;
  max-height: 100%;
}

.sidebarWrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  max-height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: var(--ui-color-secondary-bg);
}
