.actionItem {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
  align-items: center;
  padding: var(--ui-space-100) var(--ui-space-200);
  height: var(--ui-space-600);
  border-radius: var(--ui-space-100);
  border: 1px solid var(--ui-color-secondary-bg);
  user-select: none;

  &:hover {
    background-color: var(--ui-color-secondary-bg);
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &.active {
    background-color: var(--ui-color-magenta-bg);
  }

  img {
    width: auto;
    height: var(--ui-space-200);
    margin-right: var(--ui-space-100);
  }

  .badge {
    border-radius: var(--ui-space-100);
    background-color: var(--ui-color-blue-bg);
    padding: var(--ui-space-100);
    margin-left: var(--ui-space-100);
  }

  .loader {
    margin-left: var(--ui-space-100);
  }
}
