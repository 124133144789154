@import "@interactio/component-library/styles";

.modal {
  position: fixed;
  width: 600px;
  overflow: auto;
  background: white;
  display: flex;
  flex-direction: column;
  @include shadow("soft");
  z-index: 2;

  &.dragged {
    z-index: 999999;

    .header {
      background-color: var(--ui-color-gray-line);
    }
  }

  .header {
    cursor: move;
    background-color: var(--ui-color-secondary-bg);
    padding: var(--ui-space-200);

    * {
      pointer-events: none;
    }

    .closeIcon {
      cursor: pointer;
      pointer-events: auto;
    }
  }

  .content {
    max-height: 50vh;
    overflow-y: auto;

    iframe {
      width: 100%;
      height: 100%;
      display: block;
    }
  }

  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.1);
  }
}
