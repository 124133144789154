.pluginHook {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: auto;
  box-sizing: border-box;
  padding: 5px;
  border: 1px solid var(--ui-color-secondary-bg);

  > * {
    max-width: 100%;
    max-height: 100%;
    flex: 1;
  }

  .hookName {
    display: none;
  }

  .hookName:only-child {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
