@import "@interactio/component-library/styles";

.container {
  position: fixed;
  bottom: var(--ui-space-400);
  right: var(--ui-space-400);
  width: 400px;
  z-index: var(--z-index-notifications);
  background-color: var(--ui-color-secondary-bg);
  border-radius: 5px;
  @include shadow("soft");
}
